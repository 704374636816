import { firebaseApp } from "Components";
import { getAuth, signInAnonymously, signOut, User } from "firebase/auth";
import LogRocket from "logrocket";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";

interface UserContext {
  user: User;
  logout: () => void;
}

const Context = createContext<UserContext>({
  user: {} as unknown as User,
  logout: () => {},
});

export const useUser = () => useContext(Context);

export const getLoginUrl = () => {
  const params = new URLSearchParams();
  params.set("redirect", `${process.env.PUBLIC_URL}?start`);
  return `/user/login?${params.toString()}`;
};

export const UserProvider = ({
  children,
}: {
  children?: ReactNode | undefined;
}) => {
  const [user, isLoading] = useAuthState(getAuth(firebaseApp));

  const logout = useCallback(async () => {
    await signOut(getAuth(firebaseApp));
    window.location.href = getLoginUrl();
  }, []);

  useEffect(() => {
    if (!isLoading && !user) {
      signInAnonymously(getAuth(firebaseApp));
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "dev" && user?.uid) {
      LogRocket.identify(user.uid, {
        name: user.displayName as string,
        email: user.email as string,
        phone: user.phoneNumber as string,
      });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Context.Provider value={{ user, logout }}>{children}</Context.Provider>
  );
};
