import React, { ReactNode } from "react";
import { css, styled } from "styled-components";
import { Body1, H1, H2 } from "melodies-source/Text";
import { Card as MelodiesCard } from "melodies-source/Card";
import BgImage from "assets/images/background-welcome.png";
import { ReactComponent as Logo } from "assets/svg/logo-filled.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useContactContext } from "Components/ContactContext";
import { getLoginUrl } from "auth";

interface Heading {
  icon: ReactNode;
  text: string;
}

interface Actions {
  buttons: ReactNode;
  link?: "login" | "contact";
  custom?: ReactNode;
}

interface Props {
  children: ReactNode;
  size?: "small" | "large";
  welcome?: boolean;
  heading?: Heading;
  actions?: Actions;
}

export type CardProps = Props;

export const Card: React.FC<Props> = ({
  children,
  size = "small",
  welcome = false,
  heading,
  actions,
}) => {
  const isMobile = useMobileMediaQuery();
  const { setShowContact } = useContactContext();
  const Heading = isMobile ? H2 : H1;

  let actionLink;

  if (actions) {
    switch (actions.link) {
      case "login":
        actionLink = (
          <Body1>
            Already have an account? <a href={getLoginUrl()}>Log in</a>
          </Body1>
        );
        break;
      case "contact":
        actionLink = (
          <Body1>
            Questions or issues?{" "}
            <a href={`#contact`} onClick={() => setShowContact(true)}>
              Contact Us
            </a>
          </Body1>
        );
        break;
      default:
        actionLink = isMobile ? null : <div />;
    }
  }

  return (
    <StyledCard size={welcome ? "large" : size} isElevated>
      <>
        {welcome && (
          <Welcome image={BgImage}>
            <Heading>Welcome to </Heading>
            <StyledLogo />
          </Welcome>
        )}
        <Content>
          {heading && (
            <CardHeading>
              {heading.icon}
              <H2>{heading.text}</H2>
            </CardHeading>
          )}
          {children}
        </Content>
        {actions && (
          <Actions>
            {actions.custom || actionLink}
            <Buttons>{actions.buttons}</Buttons>
          </Actions>
        )}
      </>
    </StyledCard>
  );
};

type StyledCardProps = Pick<Props, "size">;

const StyledCard = styled(MelodiesCard)<StyledCardProps>`
  background: white;
  max-width: ${({ size }) => (size === "small" ? "500px" : "900px")};
  margin: auto;
  padding: 0;
  border-radius: 20px;

  a {
    color: #00e;
  }
`;

const paddingStyle = css`
  padding: 38px 42px;

  ${({ theme }) => theme.media.mobile} {
    padding: 24px 28px;
  }
`;
const Content = styled.div`
  ${paddingStyle}

  ${({ theme }) => theme.media.desktop} {
    min-height: 350px;
  }
`;

interface WelcomeProps {
  image: string;
}

const Welcome = styled.div<WelcomeProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 20px 20px 0px 0px;
  background: url(${({ image }) => image}) lightgray 0px / cover no-repeat;
  background-color: rgba(27, 0, 118, 0.5);
  background-position: top;
  padding: 60px 40px;
  color: white;

  & * {
    z-index: 2;
    color: white;
  }

  &::before {
    content: "";
    background: rgba(27, 0, 118, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 0px 0px;
  }

  ${({ theme }) => theme.media.mobile} {
    gap: 10px;
    padding: 40px 20px;
  }
`;

const StyledLogo = styled(Logo)`
  ${({ theme }) => theme.media.mobile} {
    height: 40px;
  }
`;

const CardHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;

  ${H2} {
    color: #1b0076;
  }

  ${({ theme }) => theme.media.mobile} {
    gap: 5px;
    margin-bottom: 20px;

    ${H2} {
      line-height: 32px;
    }
  }
`;

const Actions = styled.div`
  ${paddingStyle}
  padding-top: 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }
`;
