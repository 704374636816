import {
  BrowserRouter as Router,
  Routes as RouterRoutes,
  Route,
} from "react-router-dom";
import { Signup } from "./Signup";
import { SignupProvider } from "Components";

export const Routes = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <RouterRoutes>
        <Route
          path="*"
          Component={() => (
            <SignupProvider>
              <Signup />
            </SignupProvider>
          )}
        />
      </RouterRoutes>
    </Router>
  );
};
