import {
  Email,
  ArtistSearch,
  ArtistConfirm,
  ArtistAffiliation,
  ContactInfo,
  AdditionalInfo,
  VerificationSent,
  EnterPhone,
  VerifyPhone,
  EnterPassword,
  AccountCreated,
  PhoneConfirmed,
  ExistingArtist,
  VerificationLinkSent,
  Login,
  ForgotPassword,
  ResetPassword,
  ArtistInfo,
  AddArtist,
  DuplicateArtist,
  YourAffiliation,
  RequestSent,
} from "./Components";
import {
  ArtistSizes,
  Profile,
  SignupDoc,
  Steps,
  useSignupContext,
} from "Components";
import { MatchesFound } from "./Components/MatchesFound";
import { User } from "@firebase/auth";

type NextFunction = (
  doc: SignupDoc,
  size: ArtistSizes,
  user: User,
  profile: Profile,
  hasPassword: boolean,
) => keyof typeof AppStateMap;

type AppState = {
  component: () => JSX.Element;
  next?: keyof typeof AppStateMap | NextFunction;
};

type AppStates = {
  [i in (typeof Steps)[number]]: AppState;
};

const nextConfirm: NextFunction = (doc, size, user) =>
  user.isAnonymous ? "artistAffiliation" : "yourAffiliation";

export const AppStateMap: AppStates = {
  email: {
    component: Email,
    next: "artistSearch",
  },
  addArtist: {
    component: AddArtist,
    next: "artistConfirm",
  },
  login: { component: Login, next: "redirectToAp" },
  forgotPassword: { component: ForgotPassword, next: "resetPassword" },
  resetPassword: { component: ResetPassword, next: "redirectToAp" },
  artistSearch: {
    component: ArtistSearch,
    next: "artistConfirm",
  },
  existingArtist: {
    component: ExistingArtist,
    next: (doc, size, user) =>
      user.isAnonymous ? "artistAffiliation" : "yourAffiliation",
  },
  artistConfirm: {
    component: ArtistConfirm,
    next: nextConfirm,
  },
  artistReconfirm: {
    component: ArtistConfirm,
    next: nextConfirm,
  },
  matchesFound: {
    component: MatchesFound,
    next: "artistReconfirm",
  },
  artistInfo: {
    component: ArtistInfo,
    next: (doc, size, user) =>
      user.isAnonymous ? "artistAffiliation" : "yourAffiliation",
  },
  artistAffiliation: {
    component: ArtistAffiliation,
    next: "contactInfo",
  },
  yourAffiliation: {
    component: YourAffiliation,
    next: (doc, size, user, profile, hasPassword) =>
      size === "large"
        ? "additionalInfo"
        : doc.emailVerified
        ? size === "medium" && !profile?.verifiedPhone
          ? "enterPhone"
          : hasPassword
          ? doc.action === "request-access"
            ? "requestSent"
            : "accountCreated"
          : "enterPassword"
        : "verificationSent",
  },
  contactInfo: {
    component: ContactInfo,
    next: (doc, size) =>
      size === "large" ? "additionalInfo" : "verificationSent",
  },
  requestSent: {
    component: RequestSent,
  },
  additionalInfo: {
    component: AdditionalInfo,
    next: (doc, size, user, profile) =>
      !doc.emailVerified
        ? "verificationSent"
        : profile?.verifiedPhone
        ? doc.action === "request-access"
          ? "requestSent"
          : "accountCreated"
        : "enterPhone",
  },
  verificationSent: {
    component: VerificationSent,
    next: "enterPhone",
  },
  verificationLinkSent: {
    component: VerificationLinkSent,
    next: "verifyPhone",
  },
  duplicateArtist: {
    component: DuplicateArtist,
  },
  enterPhone: {
    component: EnterPhone,
    next: (doc, size) =>
      ["large", "medium"].includes(size) ? "verifyPhone" : "enterPassword",
  },
  verifyPhone: {
    component: VerifyPhone,
    next: (doc, size, user, profile, hasPassword) =>
      hasPassword
        ? doc.action === "request-access"
          ? "requestSent"
          : "accountCreated"
        : "enterPassword",
  },
  phoneConfirmed: {
    component: PhoneConfirmed,
    next: "enterPassword",
  },
  enterPassword: {
    component: EnterPassword,
    next: (doc) =>
      doc.action === "request-access" ? "requestSent" : "accountCreated",
  },
  accountCreated: {
    component: AccountCreated,
    next: "redirectToAp",
  },
  redirectToAp: {
    component: () => <></>,
  },
};

export const Signup = () => {
  const { data } = useSignupContext();
  const state =
    AppStateMap[
      data?.steps?.length > 0 ? data?.steps[data?.steps?.length - 1] : "email"
    ];

  return <state.component />;
};
