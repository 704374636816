import { Layout, useSignupContext } from "Components";
import { Body1, H1, H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { ReactComponent as CheckIcon } from "assets/svg/check-circle-green.svg";
import { styled } from "styled-components";
import { useUser } from "auth";
import { useNavigate } from "react-router-dom";

export const AccountCreated = () => {
  const { user } = useUser();
  const { handleStart, data } = useSignupContext();
  const isDesktop = useDesktopMediaQuery();
  const navigate = useNavigate();
  const Heading = isDesktop ? H1 : H2;
  const handleAdd = () => {
    navigate("/"); // This line is used for tracking purposes and does not actually affect navigation
    handleStart({ email: user!.email as string });
  };

  const handleDone = () => {
    window.location.href = "/";
  };

  return (
    <Layout>
      <Container>
        <CheckIcon width={isDesktop ? 70 : 60} />
        <Heading>
          {data?.action === "new" ? "Account Created" : "Artist Added"}
        </Heading>
        <Body1>
          {data.size === "large"
            ? "We are reviewing your artist account. A MAX representative will reach out personally to ensure yor account is optimized and ready."
            : "You successfully signed up, and we’ve verified your account."}
        </Body1>
        <ContinueButton onClick={handleDone}>
          Go to your dashboard
        </ContinueButton>
        <Button text onClick={handleAdd}>
          Add another artist
        </Button>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

const ContinueButton = styled(Button)`
  margin: 30px 0 5px 0;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
  }
`;
