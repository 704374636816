import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import {
  useCollectionOnce,
  useDocument,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { firestore, useSpotifyRecentRelease, functions } from "Components";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
} from "firebase/auth";
import { AppStateMap } from "Routes/Signup";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "auth";

export const Steps = [
  "email",
  "addArtist",
  "login",
  "forgotPassword",
  "resetPassword",
  "artistSearch",
  "artistConfirm",
  "matchesFound",
  "artistReconfirm",
  "artistInfo",
  "existingArtist",
  "artistAffiliation",
  "yourAffiliation",
  "contactInfo",
  "requestSent",
  "additionalInfo",
  "verificationSent",
  "verificationLinkSent",
  "duplicateArtist",
  "enterPhone",
  "verifyPhone",
  "phoneConfirmed",
  "enterPassword",
  "accountCreated",
  "redirectToAp",
] as const;

export interface SignupDoc extends DocumentData {
  uid: string;
  steps: (typeof Steps)[number][];
}

export type ArtistSizes = "large" | "medium" | "small" | "tiny";

type OnboardingAPI = {
  action: string;
  email?: string;
  spotifyArtistId?: string;
  signupId?: string;
  urlParams?: string;
  usePortalAuth?: boolean;
};

export type Profile = {
  title?: string;
  companyName?: string;
  affiliation?: string;
  verifiedPhone?: string;
  name?: {
    firstName?: string;
    lastName?: string;
  };
};

interface SignupContextProps {
  active?: DocumentSnapshot<SignupDoc, DocumentData>;
  data: SignupDoc;
  size: ArtistSizes;
  handleSave: (data: Partial<SignupDoc>) => Promise<void>;
  handleNext: (
    data?: Partial<SignupDoc>,
    nextState?: (typeof Steps)[number],
  ) => Promise<void>;
  handleBack: (data?: Partial<SignupDoc>) => Promise<void>;
  handleStart: ({ email }: { email: string }) => Promise<
    HttpsCallableResult<{
      status: string;
      signupId: string;
    }>
  >;
  handleSpotify: (manual?: boolean) => Promise<void>;
  handleLookup: (spotifyId: string) => Promise<string>;
  profile?: Profile;
  lastEmail: string;
}

//@ts-ignore
export const SignupContext = createContext<SignupContextProps>({});

export const SignupProvider = ({ children }: { children: ReactNode }) => {
  const auth = getAuth();
  const { user, logout } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [profile] = useDocumentData<Profile>(
    doc(firestore, "profiles", user.uid),
  );
  const onboarding = httpsCallable<
    OnboardingAPI,
    { status: string; signupId: string }
  >(functions, "signup-account");
  const [signupsCollection] = useState<CollectionReference<SignupDoc>>(
    collection(firestore, `artist_signups`) as CollectionReference<SignupDoc>,
  );
  const [signups, l1, e1] = useCollectionOnce(
    query(signupsCollection, where("uid", "==", user.uid)),
  );
  const [activeId, setActiveId] = useState<string>("fakeid");
  const [active, l2, e2] = useDocument<SignupDoc>(
    doc(firestore, `artist_signups`, activeId) as DocumentReference<
      SignupDoc,
      DocumentData
    >,
  );
  const [lastEmail, setLastEmail] = useState("");
  const [hasPassword, setHasPassword] = useState<boolean | null>(null);
  const albumsSearch = useSpotifyRecentRelease();

  const handleSave = (
    update: Partial<SignupDoc>,
    ref: DocumentReference<SignupDoc, DocumentData> = active!.ref,
  ) => {
    return setDoc<SignupDoc, DocumentData>(ref, { ...update }, { merge: true });
  };

  const handleStart = ({ email }: { email: string }) => {
    setLastEmail(email);
    return onboarding({
      action: "init",
      email,
      urlParams: window.location.search,
      usePortalAuth: true,
    }).then((res) => {
      switch (res?.data?.status) {
        case "ok":
          setActiveId(res.data?.signupId);
          if (!user.isAnonymous) {
            handleSave(
              { steps: ["addArtist"] },
              doc(
                firestore,
                `artist_signups`,
                res.data?.signupId,
              ) as DocumentReference<SignupDoc, DocumentData>,
            );
          }
          break;
        case "require-resume":
          setActiveId("resume");
          break;
        case "require-login":
          logout();
          break;
        default:
          break;
      }
      return res;
    });
  };

  useEffect(() => {
    if (l1) {
      return;
    }
    if (user.isAnonymous) {
      if (signups?.empty) {
        setActiveId("awaitingemail");
      } else {
        const incomplete = signups?.docs.find(
          (d) => d.data().status === "incomplete",
        );
        if (activeId === "fakeid" && incomplete) {
          setActiveId(incomplete.id);
        }
      }
    } else {
      const queryParams = new URLSearchParams(location.search);
      let start = false;
      if (queryParams.has("start")) {
        start = true;
        queryParams.delete("start");
        navigate(
          {
            search: queryParams.toString(),
          },
          { replace: true },
        );
      } else if (queryParams.has("signup")) {
        const id = queryParams.get("signup") as string;
        queryParams.delete("signup");
        navigate(
          {
            search: queryParams.toString(),
          },
          { replace: true },
        );
        setActiveId(id);
        return;
      }
      if (signups?.empty || start) {
        handleStart({
          email: user!.email as string,
        });
      } else {
        const incomplete = signups?.docs.find(
          (d) => d.data().status === "incomplete",
        );
        if (activeId === "fakeid") {
          if (incomplete) {
            setActiveId(incomplete.id);
          } else {
            window.location.href = "/";
          }
        }
      }
    }
  }, [signups?.empty, l1]);

  useEffect(() => {
    if (active?.data()?.artist?.spotifyId) {
      albumsSearch(active?.data()?.artist?.spotifyId).then((albums) => {
        const release = {
          name: albums?.[0]?.name,
          cover: albums?.[0]?.images?.[0]?.url,
        };
        handleSave({ release });
      });
    }
  }, [active?.data()?.artist?.spotifyId]);

  useEffect(() => {
    if (active?.exists() && !active?.data()?.steps) {
      if (active.data()?.action === "add-user") {
        handleSave({ steps: ["yourAffiliation"] });
      } else {
        handleSave({ steps: ["email", "artistSearch"] });
      }
    }
  }, [active?.id]);

  useEffect(() => {
    if (active?.data()?.email) {
      fetchSignInMethodsForEmail(auth, active!.data()!.email).then(
        (signInMethods) => {
          if (
            signInMethods.indexOf(
              EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
            ) !== -1
          ) {
            setHasPassword(true);
          } else {
            setHasPassword(false);
          }
        },
      );
    }
  }, [active?.data()?.email]);

  if (
    !["awaitingemail", "resume"].includes(activeId) &&
    (!active || activeId === "fakeid")
  ) {
    return null;
  }

  const data =
    activeId === "awaitingemail"
      ? ({ steps: ["email"] } as SignupDoc)
      : activeId === "resume"
      ? ({ steps: ["verificationLinkSent"] } as SignupDoc)
      : (active!.data() as SignupDoc);

  if (!data) {
    return null;
  }
  const handleSpotify = (manual: boolean = false) => {
    let opts = {
      action: "artist",
      signupId: activeId,
    };
    if (manual) {
      //@ts-ignore
      opts.artistName = data.artistSearch;
    } else {
      //@ts-ignore
      opts.spotifyArtistId = data?.artist?.spotifyId;
    }
    return onboarding(opts).then((res) => {
      switch (res.data.status) {
        case "ok":
          handleNext();
          break;
        case "taken":
          handleNext(undefined, "existingArtist");
          break;
        case "exists":
          handleNext(undefined, "duplicateArtist");
          break;
        default:
          console.error("fail spotify response", res);
          break;
      }
    });
  };

  const handleLookup = (spotifyId: string) => {
    let opts = {
      action: "artist",
      signupId: activeId,
      spotifyArtistId: spotifyId,
    };
    return onboarding(opts).then((res) => {
      return res.data.status;
    });
  };

  const size = data?.size;

  console.log(data);

  const handleNext = (
    update?: Partial<SignupDoc>,
    nextState?: (typeof Steps)[number],
  ) => {
    let next =
      nextState || AppStateMap[data?.steps[data?.steps?.length - 1]].next;
    if (typeof next === "function") {
      next = next(data, size, user, profile || {}, !!hasPassword);
    }
    return handleSave({
      ...update,
      //@ts-ignore
      steps: arrayUnion(next),
    });
  };

  const handleBack = (update?: Partial<SignupDoc>) => {
    return handleSave({
      ...update,
      //@ts-ignore
      steps: arrayRemove(data.steps[data.steps.length - 1]),
    });
  };

  return (
    <SignupContext.Provider
      value={{
        profile,
        active,
        data,
        size,
        handleSave,
        handleNext,
        handleBack,
        handleStart,
        handleSpotify,
        handleLookup,
        lastEmail,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

export const useSignupContext = () => useContext(SignupContext);
